import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { dataLayerRouteChange } from '@/utils/dataLayer'

// pages/404.js
export default function Custom404() {
  const router = useRouter()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      dataLayerRouteChange({ url: router.asPath })
    }
  }, [router.asPath])

  return (
    <div style={{ height: 250, margin: 20 }}>
      <h2>Page Not Found</h2>
    </div>
  )
}
